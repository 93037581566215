<template>
    <div>
        <registration-codes-form></registration-codes-form>
    </div>
</template>

<script>
import RegistrationCodesForm from '@/components/pages/registrationCodes/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        RegistrationCodesForm,
    },
    mixins: [dirtyForm],
}
</script>
