<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template v-slot:col1>
                        <date-picker-input
                            v-model="form.validFrom"
                            v-validate.immediate="'required'"
                            label="labels.valid_from"
                            :data-vv-as="$t('labels.valid_from')"
                            name="validFrom"
                            :error-messages="errors.collect('validFrom')"
                        ></date-picker-input>
                    </template>
                    <template v-slot:col2>
                        <date-picker-input
                            v-model="form.validTo"
                            v-validate.immediate="'required'"
                            label="labels.valid_to"
                            :data-vv-as="$t('labels.valid_to')"
                            name="validTo"
                            :error-messages="errors.collect('validTo')"
                        ></date-picker-input>
                    </template>
                </form-two-col-row>
                <form-two-col-row>
                    <template v-slot:col1>
                        <entity-select-input
                            v-model="form.company"
                            v-validate.immediate="'required'"
                            label="labels.company"
                            :data-vv-as="$t('labels.company')"
                            :entity="SelectApiClientType.COMPANY"
                            autocomplete
                            name="company"
                            :error-messages="errors.collect('company')"
                        ></entity-select-input>
                    </template>
                    <template v-slot:col2>
                        <text-input
                            v-if="!form['@id']"
                            v-model="form.customCodePrefix"
                            v-validate="'length:4|space'"
                            label="labels.custom_code_prefix"
                            :data-vv-as="$t('labels.custom_code_prefix')"
                            name="customCodePrefix"
                            :error-messages="errors.collect('customCodePrefix')"
                        ></text-input>
                        <read-only-text
                            v-else
                            :value="form.code"
                            label="labels.code"
                        ></read-only-text>
                    </template>
                </form-two-col-row>
                <form-two-col-row>
                    <template v-slot:col1>
                        <entity-select-input
                            v-model="form.companyAddresses"
                            v-validate="''"
                            label="labels.company_addresses"
                            :data-vv-as="$t('labels.company_addresses')"
                            :entity="SelectApiClientType.COMPANY_ADDRESS"
                            :query-params="{
                                company: form.company,
                            }"
                            :request-disabled="form.company === null"
                            multi-select
                            name="companyAddresses"
                            :error-messages="errors.collect('companyAddresses')"
                        ></entity-select-input>
                    </template>
                    <template v-slot:col2>
                        <entity-select-input
                            v-model="form.companyAllowances"
                            v-validate="''"
                            label="labels.company_allowances"
                            :data-vv-as="$t('labels.company_allowances')"
                            :entity="SelectApiClientType.COMPANY_ALLOWANCE"
                            :query-params="{
                                company: form.company,
                                withoutCompanyTeam: 1,
                            }"
                            :request-disabled="form.company === null"
                            multi-select
                            name="companyAllowances"
                            :error-messages="
                                errors.collect('companyAllowances')
                            "
                        ></entity-select-input>
                    </template>
                </form-two-col-row>
                <form-two-col-row>
                    <template v-slot:col1>
                        <entity-select-input
                            v-model="form.companyTeams"
                            v-validate="''"
                            label="labels.company_teams"
                            :data-vv-as="$t('labels.company_teams')"
                            :entity="SelectApiClientType.COMPANY_TEAMS"
                            :query-params="{ companyId }"
                            :request-disabled="form.company === null"
                            multi-select
                            name="companyTeams"
                            :error-messages="errors.collect('companyTeams')"
                        ></entity-select-input>
                    </template>
                </form-two-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import selectapiType from '@/store/type/selectapiType'
import IdFromIri from '@/services/IdFromIri'
import FormBase from '@/components/mixins/FormBase'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import ReadOnlyText from '@/components/form/Inputs/ReadOnlyText'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        EntitySelectInput,
        DatePickerInput,
        ReadOnlyText,
    },
    mixins: [FormBase],
    data() {
        return {
            api: RestApiType.REGISTRATION_CODES,
            mapper: MapperType.DEFAULT_FORM,
            SelectApiClientType: selectapiType.clientTypes,
            form: {
                company: null,
                companyAddresses: [],
                companyAllowances: [],
                companyTeams: [],
                validFrom: null,
                validTo: null,
                customCodePrefix: null,
            },
            editRoute: routeType.REGISTRATION_CODES_EDIT,
            listRoute: routeType.REGISTRATION_CODES_LIST,
            companyNames: [],
        }
    },
    computed: {
        title() {
            const id = parseInt(this.companyId)
            const name =
                this.companyNames.find((val) => val.id === id)?.name ?? '...'
            return name
        },
        companyId() {
            return IdFromIri.getId(this.form.company)
        },
    },
    watch: {
        'form.company'(newCompany, oldCompany) {
            if (oldCompany) {
                this.form.companyAddresses = []
                this.form.companyAllowances = []
                this.form.companyTeams = []
            }
        },
    },
    mounted() {
        this.$store
            .dispatch(
                selectapiType.getActionName(
                    selectapiType.clientTypes.COMPANY,
                    selectapiType.actions.LOAD
                )
            )
            .then((data) => (this.companyNames = data))
    },
}
</script>
