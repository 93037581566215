<template>
    <v-text-field
        :label="labelText"
        :value="value"
        hide-details="auto"
        readonly
        outlined
        disabled
        :suffix="suffix"
    ></v-text-field>
</template>

<script>
import BaseInputMixin from './InputMixins/BaseInputMixin'

export default {
    mixins: [BaseInputMixin],
    props: {
        value: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        suffix: {
            type: String,
            default: undefined,
        },
    },
}
</script>
